import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";
import CoinDetailsDrawer from "components/drawers/CoinDetailsDrawer";
import Loading from "components/Loading/Loading";
import { HSeparator } from "components/separator/Separator";
import { useStrategies } from "contexts/StrategiesContext";
import { getToast } from "helpers/formatters";
import numeral from "numeral";
import { useState } from "react";


const LogsPanel = (props: { strategy: BullFolio.Strategy }) => {

  const { strategy } = props;

  const { getStrategyLogs } = useStrategies();
  const toast = useToast();

  const [logs, setLogs] = useState<BullFolio.Log[] | null>(null);
  const [logDoc, setLogDoc] = useState<BullFolio.LogDoc | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [tokenId, setTokenId] = useState<string | null>(null);

  const openModal = (id: string) => {
    setTokenId(id);
    setOpen(true);
  }

  const handleGetData = async () => {
    setIsLoading(true);
    console.log("getting data")
    const data = await getStrategyLogs(strategy.id);
    if(data) {
      setLogDoc(data.logDoc);
      setLogs(data.logs);
      console.log("got data ", data);
    }else{
      // toast error
      toast(getToast("warning", "No Data Found!", "Please contact support.4"));
    }
    setIsLoading(false);
  }

  return(
    <>
      <Card>
        <Text>Past Trades - Activity</Text>
        <Button onClick={() => handleGetData()}>Get Logs</Button>
        <Text>Overview</Text>
        <HSeparator />
        <Text>Logs</Text>
        {logs?.map((log, i) => {
          return (
            <Card key={i}>
              <Text fontWeight={"extrabold"}>{log.date[0]}</Text>
              {log?.token?.map((token, index) => {
                return(
                  <Box
                    key={index}
                  >
                    <Text>{token.symbol.toUpperCase()}/{strategy.against.toUpperCase()} - {log.type[index].toUpperCase()} at {strategy.against === "usd" ? "$": "₿"}{log.price[index]} (=</Text>
                    <Button onClick={() => openModal(token.id)}>Token Info</Button>
                  </Box>
                )
              })}
              <HSeparator />
            </Card>
          )
        })}
        {isLoading ? (
          <Loading text="Loading logs..." />
        ):null}
      </Card>

      <CoinDetailsDrawer
        isOpen={open}
        close={() => setOpen(false)}
        tokenId={tokenId}
      />
    </>
  )
};

export default LogsPanel;
import { Box, Button, Link, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import BreadCrumpStrip from "components/breadcrumb/Breadcrumb";
import Card from "components/card/Card";
import StrategyBox from "components/card/StrategyBox";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useUser } from "contexts/UserContext";
import { links } from "helpers/links";
import { useEffect, useState } from "react";

const MyStrategiesPage = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const LIMIT = 12;

  const { getMyStrategies } = useStrategies();
  const { userData } = useUser();

  const [strategies, setStrategies] = useState<BullFolio.Strategy[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(userData) {
      (async () => {
        setIsLoading(true);
        const res = await getMyStrategies();
        console.log(res);
        setStrategies(res);
        setIsLoading(false);
      })();
    }
  }, [userData]);

  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <BreadCrumpStrip
        links={[{
          href: `/`,
          name: "My Strategies"
        }]}
        additional={{ mb: "4" }}
      />
      <Card mb="6">
        <Text textColor={textColor} fontSize="2xl" fontWeight={"extrabold"}>
          My Strategies
        </Text>
        <Text textColor={textColor}>
          Your Strategy Limit: {LIMIT}.
        </Text>
      </Card>
      {!isLoading ? (
        <>
          {strategies?.length>0 ? (
            <SimpleGrid columns={3} gap="20px">
              {strategies.map(strategy => {
                return (
                  <StrategyBox
                    key={strategy.id}
                    strategy={strategy}
                  />
                )
              })}
            </SimpleGrid>
          ):(
            <Text textAlign={"center"} mt="14">No Strategies Found.</Text>
          )}
          {strategies.length>=0 && strategies.length<LIMIT ? (
            <Link href={`/#${links.createStrategy}`}>
              <Button variant={"darkBrand"} w="100%" mt="12">
                Create Strategy
              </Button>
            </Link>
          ):(
            <Card mt="12">
              <Text textAlign={"center"}>Strategies Limit Reached.</Text>
            </Card>
          )}
        </>
      ):(
        <Loading text="Getting your strategies..."/>
      )}
    </Box>
  );
};

export default MyStrategiesPage;
import { Badge, Box, Button, Flex, LightMode, Text, useColorModeValue } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import Card from "components/card/Card";

const AboutPanel = (props: { strategy: BullFolio.Strategy }) => {
  const { strategy } = props;
  
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

  return(
    <>
      <Card mb="4">
        <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Description</Text>
        <Text>{strategy.description}</Text>
      </Card>
      <Card mb="4">
        <Text fontSize={"xl"} fontWeight={"bold"} mb="1">Other Information</Text>
        <Box px="2">
          <Flex mb="1">
            <Text mr="4">Against</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.against.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Timeframe</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.timeframe.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Type</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.type.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Created</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.created?.toUpperCase()}</Badge>
          </Flex>
          <Flex mb="1">
            <Text mr="4">Events</Text>
            {strategy.events.map((x) => {
              return (
                <Badge key={x.type} variant={"outline"} width="min-content" ml="2">{x.type.toUpperCase()}</Badge>
              )
            })}
          </Flex>
          <Flex>
            <Text mr="4">Author</Text>
            <Badge variant={"outline"} width="min-content" ml="2">{strategy.author}</Badge>
          </Flex>
        </Box>
      </Card>
      <Card flexDirection={{ base: 'column', md: 'row', lg: 'row' }} alignItems='center'>
        <Flex direction='column'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Seems Suspicious?
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            We will take a look at it.
          </Text>
        </Flex>
        <LightMode>
          <Button
            colorScheme='red'
            variant='outline'
            mt={{ base: '20px', md: '0' }}
            _hover={{ bg: 'whiteAlpha.100' }}
            _focus={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            p='15px 40px'
            fontSize='sm'
            h='44px'
            fontWeight='500'
            ms='auto'>
            Report
          </Button>
        </LightMode>
      </Card>
    </>
  )
};

export default AboutPanel;

import React, { useState } from 'react';

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
	SimpleGrid,
	Select,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Center
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/admin/strategies/strategyPage/components/Banner';
import NFT from 'components/card/NFT';
import { SearchBar } from 'views/admin/strategies/strategyPage/components/Search';
import { HSeparator } from 'components/separator/Separator';

// Assets
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import NftBanner3 from 'assets/img/nfts/NftBanner3.png';
import avatarSimmmple from 'assets/img/avatars/avatarSimmmple.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { useHistory } from "react-router-dom";
import { useQuery } from "helpers/formatters";

import {
	MdDashboard,
	MdApps,
	MdOutlineCollections,
	MdFormatPaint,
	MdAccessTime,
	MdOutlineLocalOffer
} from 'react-icons/md';
import { IoMdHeartEmpty } from 'react-icons/io';
import { useEffect } from 'react';
import { useStrategies } from 'contexts/StrategiesContext';
import { useUser } from 'contexts/UserContext';
import { BullFolio } from 'bullfolio-types';
import { links } from 'helpers/links';
import AboutPanel from './panels/About';
import Loading from 'components/Loading/Loading';
import NotFound from 'views/admin/main/others/404';
import BacktestPanel from './panels/BacktestPanel';
import AdminPanel from './panels/AdminPanel';
import MyPanel from './panels/MyPanel';
import CommentsPanel from './panels/CommentsPanel';
import LogsPanel from './panels/LogsPanel';
import BreadCrumpStrip from 'components/breadcrumb/Breadcrumb';


const StrategyPage = () => {
	let [ tabState, setTabState ] = useState('about');

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const buttonBg = useColorModeValue('transparent', 'navy.800');
	const hoverButton = useColorModeValue({ bg: 'gray.100' }, { bg: 'whiteAlpha.100' });
	const activeButton = useColorModeValue({ bg: 'gray.200' }, { bg: 'whiteAlpha.200' });
	const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	const btnColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	let panelExample = (
		<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px'>
			<NFT
				name='Swipe Circles'
				author='By Peter Will'
				bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
				image={Nft4}
				currentbid='0.91 ETH'
				download='#'
			/>
			<NFT
				name='Colorful Heaven'
				author='By Mark Benjamin'
				bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
				image={Nft5}
				currentbid='0.91 ETH'
				download='#'
			/>
			<NFT
				name='3D Cubes Art'
				author='By Manny Gates'
				bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
				image={Nft6}
				currentbid='0.91 ETH'
				download='#'
			/>
			<NFT
				name='ETH AI Brain'
				author='By Nick Wilson'
				bidders={[ Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1 ]}
				image={Nft2}
				currentbid='0.91 ETH'
				download='#'
			/>
		</SimpleGrid>
	);

	const { getStrategyById } = useStrategies();
  const { user, userData } = useUser();
  const query = useQuery();
  const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
  const [strategy, setStrategy] = useState<BullFolio.Strategy | null>(null);

  useEffect(() => {
    const id = query.get("id");
    if(id) {
			setIsLoading(true);
			if(userData && user) {
				(async () => {
					const res = await getStrategyById(id);
					setStrategy(res);
					setIsLoading(false);
					if(!res) {
						console.log("redirect");
					}
				})();
			}
    }else{
      history.push(`/#${links.strategyMarketplace}`);
    }
  }, [query, user, userData]);

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			<BreadCrumpStrip
        links={[{
          href: `/#${links.strategyMarketplace}`,
          name: "Marketplace"
        }, {
          href: `/`,
          name: strategy?.name || "Strategy Details"
        }]}
        additional={{ mb: "4" }}
      />
			{strategy ? (
				<Box>
					{/* Main Fields */}
					<Box mb='0px' display={{ base: 'block', lg: 'grid' }}>
						<Flex flexDirection='column'>
							<Banner
								strategy={strategy}
							/>
						</Flex>
					</Box>
					<Tabs variant='soft-rounded' colorScheme='brandTabs'>
						<TabList mx={{ base: '10px', lg: '30px' }} overflowX={{ sm: 'scroll', lg: 'unset' }}>
							<Flex justify={{ base: 'start', md: 'center' }} w='100%'>
								<Tab
									pb='0px'
									flexDirection='column'
									onClick={function() {
										setTabState('about');
									}}
									me='50px'
									bg='unset'
									_selected={{
										bg: 'none'
									}}
									_focus={{ border: 'none' }}
									minW='max-content'>
									<Flex align='center'>
										<Icon color={textColor} as={MdOutlineCollections} w='20px' h='20px' me='8px' />
										<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											About
										</Text>
										<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
											0
										</Text>
									</Flex>
									<Box
										height='4px'
										w='100%'
										transition='0.1s linear'
										bg={tabState === 'about' ? 'brand.500' : 'transparent'}
										mt='15px'
										borderRadius='30px'
									/>
								</Tab>
								<Tab
									onClick={function() {
										setTabState('backtesting');
									}}
									pb='0px'
									me='50px'
									bg='unset'
									_selected={{
										bg: 'none'
									}}
									_focus={{ border: 'none' }}
									minW='max-content'
									flexDirection='column'>
									<Flex align='center'>
										<Icon color={textColor} as={MdFormatPaint} w='20px' h='20px' me='8px' />
										<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											Performance
										</Text>
										<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
											4
										</Text>
									</Flex>
									<Box
										height='4px'
										w='100%'
										transition='0.1s linear'
										bg={tabState === 'backtesting' ? 'brand.500' : 'transparent'}
										mt='15px'
										borderRadius='30px'
									/>
								</Tab>
								<Tab
									pb='0px'
									flexDirection='column'
									onClick={function() {
										setTabState('logs');
									}}
									me='50px'
									bg='unset'
									_selected={{
										bg: 'none'
									}}
									_focus={{ border: 'none' }}
									minW='max-content'>
									<Flex align='center'>
										<Icon color={textColor} as={IoMdHeartEmpty} w='20px' h='20px' me='8px' />
										<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											Trades
										</Text>
										<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
											12
										</Text>
									</Flex>
									<Box
										height='4px'
										w='100%'
										transition='0.1s linear'
										bg={tabState === 'logs' ? 'brand.500' : 'transparent'}
										mt='15px'
										borderRadius='30px'
									/>
								</Tab>
								<Tab
									pb='0px'
									flexDirection='column'
									onClick={function() {
										setTabState('comments');
									}}
									me='50px'
									bg='unset'
									_selected={{
										bg: 'none'
									}}
									_focus={{ border: 'none' }}
									minW='max-content'>
									<Flex align='center'>
										<Icon color={textColor} as={MdAccessTime} w='20px' h='20px' me='8px' />
										<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											Comments
										</Text>
									</Flex>
									<Box
										height='4px'
										w='100%'
										transition='0.1s linear'
										bg={tabState === 'comments' ? 'brand.500' : 'transparent'}
										mt='15px'
										borderRadius='30px'
									/>
								</Tab>
								<Tab
									pb='0px'
									flexDirection='column'
									onClick={function() {
										setTabState('my');
									}}
									me='50px'
									bg='unset'
									_selected={{
										bg: 'none'
									}}
									_focus={{ border: 'none' }}
									minW='max-content'>
									<Flex align='center'>
										<Icon color={textColor} as={MdOutlineLocalOffer} w='20px' h='20px' me='8px' />
										<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											My
										</Text>
										<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
											7
										</Text>
									</Flex>
									<Box
										height='4px'
										w='100%'
										transition='0.1s linear'
										bg={tabState === 'my' ? 'brand.500' : 'transparent'}
										mt='15px'
										borderRadius='30px'
									/>
								</Tab>
								{user.uid === strategy.userUid ? (
									<Tab
										pb='0px'
										flexDirection='column'
										onClick={function() {
											setTabState('admin');
										}}
										me='50px'
										bg='unset'
										_selected={{
											bg: 'none'
										}}
										_focus={{ border: 'none' }}
										minW='max-content'>
										<Flex align='center'>
											<Icon color={textColor} as={MdOutlineLocalOffer} w='20px' h='20px' me='8px' />
											<Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
											Admin
											</Text>
											<Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
												7
											</Text>
										</Flex>
										<Box
											height='4px'
											w='100%'
											transition='0.1s linear'
											bg={tabState === 'admin' ? 'brand.500' : 'transparent'}
											mt='15px'
											borderRadius='30px'
										/>
									</Tab>
								):null}
							</Flex>
						</TabList>
						<HSeparator mb='30px' bg={paleGray} mt='0px' />
						<TabPanels>
							<TabPanel px='0px'>
								<AboutPanel
									strategy={strategy}
								/>
							</TabPanel>
							<TabPanel px='0px'>
								<BacktestPanel
									strategy={strategy}
								/>
							</TabPanel>
							<TabPanel px='0px'>
								<LogsPanel
									strategy={strategy}
								/>
							</TabPanel>
							<TabPanel px='0px'>
								<CommentsPanel
									strategy={strategy}
								/>
							</TabPanel>
							<TabPanel px='0px'>
								<MyPanel
									strategy={strategy}
								/>
							</TabPanel>
							{user.uid === strategy.userUid ? (
								<TabPanel px='0px'>
									<AdminPanel
										strategy={strategy}
									/>
								</TabPanel>
							):null}
						</TabPanels>
					</Tabs>
				</Box>
			):(
				isLoading ? (
					<Loading text='Loading strategy...' />
				):(
					<NotFound />
				)
			)}
		</Box>
	);
};

export default StrategyPage;
import React from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartProps = {
	[x: string]: any;
};
type ChartState = {
	chartData: any[];
	chartOptions: any;
	height: string | number;
};

class LineChart extends React.Component<ChartProps, ChartState> {
	constructor(props: { chartData: any[]; chartOptions: any, height: number }) {
		super(props);

		this.state = {
			chartData: [],
			chartOptions: {},
			height: "100%"
		};
	}

	componentDidMount() {
		this.setState({
			chartData: this.props.chartData,
			chartOptions: this.props.chartOptions,
			height: this.props.height,
		});
	}

	render() {
		return (
			<ReactApexChart
				options={this.state.chartOptions}
				series={this.state.chartData}
				type='line'
				width={'100%'}
				height={this.state.height}
			/>
		);
	}
}

export default LineChart;

// Chakra imports
import { Flex, Icon, Image, Avatar, Text, useToast, useColorModeValue, Badge } from '@chakra-ui/react';
import { BullFolio } from 'bullfolio-types';
// Assets
import { MdVerified, MdOutlineContentCopy } from 'react-icons/md';

export default function Banner(props: {
	strategy: BullFolio.Strategy
}) {
	const { strategy } = props;

	const borderColor = useColorModeValue('white !important', '#0b1437 !important');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorLink = useColorModeValue('blue.500', 'white');

	return (
		<Flex justifyContent='center' align='center' direction='column' w='100%'>
			<Image
				src={strategy.image}
				w={{ base: '100%', '3xl': '100%' }}
				maxH={{ base: '28vh', '3xl': '27.5vh' }}
				h={{ base: '140px', md: '100%' }}
				borderRadius='20px'
				mb="28px"
				objectFit={"cover"}
			/>
			<Flex align='center' mb='20px'>
				<Text
					color={textColor}
					fontSize={{ base: '40px', lg: '54px' }}
					fontWeight='700'
					lineHeight='100%'
					me='6px'>
					{strategy.name}
				</Text>
				{strategy.isVerified ? <Icon as={MdVerified} h='34px' w='34px' color='blue.500' mt='12px' /> : null}
			</Flex>
			<Badge variant={"outline"} width="min-content" mb="4" ml="2">Strategy</Badge>
			<Flex
				align='center'
				mb='14px'
			>
				<Text color='secondaryGray.600' fontSize='lg' fontWeight='400' mx='4px'>
					By: {strategy.author}
				</Text>
			</Flex>
			<Text
				color={textColor}
				fontSize='lg'
				fontWeight='500'
				lineHeight='180%'
				w='860px'
				maxW='100%'
				textAlign='center'
				mb='60px'>
				{strategy.created}
			</Text>
		</Flex>
	);
}

import React, { useState, createContext, useContext } from "react";
import { functions } from "../helpers/firebase";
import { useToast } from '@chakra-ui/react'
import { getToast } from "../helpers/formatters";
import { httpsCallable } from "firebase/functions";
import { useUser } from "./UserContext";
import { BullFolio } from "bullfolio-types";

interface CoinsProviderProps {
  children: React.ReactNode
}

interface CoinsDataRes {
  coins: BullFolio.CoinData.Data[];
  nfts: BullFolio.NftData[];
}

interface CoinsContextProps {
  getAllCoins: (page: number) => Promise<CoinsDataRes | null>;
  queryCoins: (page: number, size: number, strategies: BullFolio.Strategy[], strategyCondition: BullFolio.QueryCoins.StrategyCondition, tokenIds: string[]) => Promise<BullFolio.CoinData[] | null>;
  getMarketData: () => Promise<BullFolio.MarketData | null>;
  getCoinById: (id: string) => Promise<BullFolio.CoinDetailedData | null>;
  getCoinChart: (id: string) => Promise<BullFolio.PricesData>;
  test: () => Promise<void>;
  getLevels: (priceData: BullFolio.CoinData.Price[]) => Promise<number[] | null>;
}

const CoinsContext = createContext<CoinsContextProps>({
  getAllCoins: (page) => new Promise(() => null),
  queryCoins: (page, size, strategies, strategyCondition) => new Promise(() => null),
  getMarketData: () => new Promise(() => null),
  getCoinById: (id) => new Promise(() => null),
  getCoinChart: (id) => new Promise(() => null),
  test: () => new Promise(() => {}),
  getLevels: (priceData) => new Promise(() => null),
});


export const CoinsProvider = ({ children }: CoinsProviderProps) => {

  const [allCoins, setAllCoins] = useState<BullFolio.CoinData.Data[] | null>(null);
  const [detailedCoins, setDetailedCoins] = useState<BullFolio.CoinDetailedData[] | null>(null);
  const [allQueryCoins, setAllQueryCoins] = useState<BullFolio.CoinDetailedData[] | null>(null);
  const [nfts, setNfts] = useState<any[] | null>(null);
  const [marketData, setMarketData] = useState<BullFolio.MarketData | null>(null);
  const [prices, setPrices] = useState<{[id: string]: BullFolio.PricesData}>({});

  const { user, userData } = useUser();
  const toast = useToast();

  const getAllCoins = async (page: number) => {
    if(!user || !userData) {
      return null;
    };

    if(allCoins?.length >= page*100) {
      console.log("returning coins")
      return {
        coins: allCoins,
        nfts: nfts,
      };
    }

    try{
      if(user) {
        console.log("getting coins")
        const getAllIds = httpsCallable(functions, "getAllIds");
        const result = await getAllIds();
        const data = result.data as {coins: BullFolio.CoinData.Data[]};
        console.log(data);
        const prev = allCoins ? [...allCoins] : [];
        setAllCoins([...prev, ...data.coins]);
        return {coins: data.coins, nfts: []};
      }else{
        console.log("no user");
      }
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting coins!", message));
      return null;
    }
  }

  const queryCoins = async (page: number, size: number, strategies: BullFolio.Strategy[], strategyCondition: BullFolio.QueryCoins.StrategyCondition, tokenIds: string[]) => {
    if(!user || !userData) {
      return null;
    };

    try{
      if(user) {
        console.log("querying coins")
        const queryCoinsF = httpsCallable(functions, "queryCoins", {timeout: 1000000});
        const result = await queryCoinsF({
          page: page,
          size: size,
          strategies: strategies,
          strategiesCondition: strategyCondition,
          sort: {
            direction: "asc", value: "data.market_cap",
          },
          tokenIds: tokenIds
        });
        const data = result.data as {coins: BullFolio.CoinData[]};
        console.log(data);
        return data.coins;
      }else{
        console.log("no user");
      }
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while querying coins!", message));
      return null;
    }
  }


  const getLevels = async (priceData: BullFolio.CoinData.Price[]) => {
    try{
      if(user) {
        console.log("getting levels")
        const detectLevelsForPrices = httpsCallable(functions, "detectLevelsForPrices");
        const result = await detectLevelsForPrices({
          priceData
        });
        const data = result.data as number[];
        console.log(data);
        return data;
      }else{
        console.log("no user");
      }
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting support/resistance areas!", message));
      return null;
    }
  };

  const getMarketData = async () => {
    if(!user) return null;
    if(marketData) return marketData;

    try{
      const getMarketDataF = httpsCallable(functions, "getMarketData");
      const result = await getMarketDataF();
      const data = result.data as BullFolio.MarketData;
      setMarketData(data);
      return data;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting coins!", message));
      return null;
    }
  }

  const test = async () => {
    try{
      console.log("testing")
      const backTestStrategyF = httpsCallable(functions, "migrate");
      const result = await backTestStrategyF();
      console.log(result)
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting test!", message));
    }
  }

  const getCoinById = async (id: string): Promise<BullFolio.CoinDetailedData | null> => {
    if(user && userData) {
      if(detailedCoins) {
        const coinToReturn = detailedCoins.find((element) => element.data.id === id);
        if(coinToReturn) {
          return coinToReturn;
        }
      }

      try{
        const getAllCoinsF = httpsCallable(functions, "getCoinById");
        const result = await getAllCoinsF({
          id: id,
        });
        const data = result.data as BullFolio.CoinDetailedData;
        console.log(data);
        // save to all coins
        const prev = detailedCoins ? [...detailedCoins] : [];
        setDetailedCoins([...prev, data])
        return data;
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while getting coins!", message));
        return null;
      }
    }
  }

  const getCoinChart = async (id: string) => {
    if(user && userData) {
      if(prices[id]) {
        return prices[id];
      }
      try{
        const getCoinChartF = httpsCallable(functions, "getCoinChart");
        const result = await getCoinChartF({
          tokenId: id
        });
        const data = result.data as BullFolio.PricesData;
        console.log(data);
        setPrices({
          ...prices,
          [id]: data,
        })
        return data;
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while getting coins!", message));
        return null;
      }
    }
  }

  return (
    <CoinsContext.Provider
      value={{
        getAllCoins,
        queryCoins,
        getMarketData,
        getCoinById,
        getCoinChart,
        test,
        getLevels
      }}
    >
      {children}
    </CoinsContext.Provider>
  );
};

export const useCoins = () => {
  return useContext(CoinsContext);
}
import { Box, Switch, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Select, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import LineChart from 'components/charts/LineChart';
import { dissolveKey, generateHexColorFromString, isOscillator, secondsToDayMonth } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";
import { useCoins } from "contexts/CoinsContext";
import Content from "views/admin/coinPage/components/Content";

const drawerSizes = {
  sm: '425px',
  md: "425px",
  lg: "1550px"
};

const CoinDetailsDrawer = (props: { tokenId: string, isOpen: boolean, close: () => void }) => {

  const { tokenId, isOpen, close } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const [startingBalance, setStartingBalance] = useState("1");
  const [positionSize, setPositionSize] = useState("0");
  const [span, setSpan] = useState("200");
  const [coinId, setCoinId] = useState("bitcoin");
  const [backTest, setBackTest] = useState<BullFolio.BackTest>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bitcoin, setBitcoin] = useState<BullFolio.CoinDetailedData | null>(null);
  const [tokenData, setTokenData] = useState<BullFolio.CoinDetailedData | null>(null);

  const { getCoinById } = useCoins();

  useEffect(() => {
    if(isOpen && tokenId.length>0) {
      (async function () {
        console.log("getting btc and token");
        setIsLoading(true);
        const _btc = await getCoinById("bitcoin");
        console.log("token id ", tokenId)
        const _token = await getCoinById(tokenId);
        setBitcoin(_btc);
        setTokenData(_token);
        setIsLoading(false);
      })();
    }
  }, [isOpen, tokenId]);

  useEffect(() => {
    console.log(bitcoin, tokenData)
  }, [bitcoin, tokenData]);

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">{tokenData?.data?.name || "Token"} Details</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Token Details & Info.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          {bitcoin && tokenData ? (
            <Content coin={tokenData} bitcoin={bitcoin} />
          ):null}
          {isLoading ? ( 
            <Loading text="Loading..." />  
          ):null}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CoinDetailsDrawer;
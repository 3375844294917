import React, { useState, createContext, useContext } from "react";
import { functions } from "../helpers/firebase";
import { useToast } from '@chakra-ui/react'
import { getToast } from "../helpers/formatters";
import { httpsCallable } from "firebase/functions";
import { useUser } from "./UserContext";
import { BullFolio } from "bullfolio-types";

interface StrategiesProviderProps {
  children: React.ReactNode
}

interface StrategiesContextProps {
  createStrategy: (strategy: BullFolio.Strategy) => Promise<void>;
  getMyStrategies: () => Promise<BullFolio.Strategy[] | null>;
  getAllStrategies: () => Promise<BullFolio.Strategy[] | null>;
  deleteStrategy: (id: string) => Promise<void>;
  getStrategyById: (id: string) => Promise<BullFolio.Strategy | null>;
  backTestStrategy: (data: BullFolio.Strategy, coinId: string, positionSize: number, span: number, startingBalance: number) => Promise<BullFolio.BackTest | null>,
  getStrategyLogs: (id: string) => Promise<{logDoc: BullFolio.LogDoc, logs: BullFolio.Log[]} | null>;
}

const StrategiesContext = createContext<StrategiesContextProps>({
  createStrategy: (strategy) => new Promise(() => { }),
  getMyStrategies: () => new Promise(() => null),
  getAllStrategies: () => new Promise(() => null),
  deleteStrategy: (id) => new Promise(() => {  }),
  getStrategyById: (id) => new Promise(() => null),
  backTestStrategy: (data, coinId, positionSize, span, startingBalance) => new Promise(() => null),
  getStrategyLogs: (id) => new Promise(() => null),
});


export const StrategiesProvider = ({ children }: StrategiesProviderProps) => {

  const { user, userData } = useUser();
  const toast = useToast();

  const [strategies, setStrategies] = useState<BullFolio.Strategy[] | null>(null);

  const createStrategy = async (strategyData: BullFolio.Strategy) => {
    if(!user || !userData) {
      return;
    };

    try{
      const createStrategyF = httpsCallable(functions, "createStrategy");
      const result = await createStrategyF({
        strategy: strategyData
      });
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while creating your strategy!", message));
    }
  }

  const getMyStrategies = async (): Promise<BullFolio.Strategy[] | null> => {
    if(!user || !userData) {
      return null;
    };

    try{
      const getAllUserStrategies = httpsCallable(functions, "getAllUserStrategies");
      const result = await getAllUserStrategies();
      const _strategies = result.data as BullFolio.Strategy[];
      return _strategies;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting your strategies!", message));
      return null;
    }
  }

  const getAllStrategies = async (): Promise<BullFolio.Strategy[] | null> => {
    if(!user || !userData) {
      return null;
    };
  
    if (strategies) {
      console.log("here - return")
      return strategies;
    }

    try{
      console.log("calling");
      const getAllStrategiesF = httpsCallable(functions, "getAllStrategies");
      const result = await getAllStrategiesF();
      const _strategies = result.data as BullFolio.Strategy[];
      setStrategies(_strategies);
      console.log("res ", _strategies)
      return _strategies;
    }catch(err){
      const error: any = err;
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.log(code, message, details);
      toast(getToast("error", "Something went wrong while getting strategies!", message));
      return null;
    }
  }

  const deleteStrategy = async (id: string) => {
    if(user && userData && id) {
      try{
        toast(getToast("info", "Deleting", "Deleting strategy, please wait."));
        const deleteStrategyF = httpsCallable(functions, "deleteStrategy");
        await deleteStrategyF({
          strategyId: id
        });
        toast(getToast("success", "Deleted", "Strategy was deleted."));
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while deleting strategy!", message));
      }
    }
  }

  const backTestStrategy = async (data: BullFolio.Strategy, coinId: string, positionSize: number, span: number, startingBalance: number) => {
    if(user && userData) {
      try{
        const backTestStrategyF = httpsCallable(functions, "backTestStrategy");
        const res = await backTestStrategyF({
          strategyData: data,
          coinId,
          positionSize,
          span,
          startingBalance
        });
        const result = res.data as BullFolio.BackTest;
        return result;
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while back-testing strategy!", message));
      }
    }
  }
 
  const getStrategyById = async (id: string) => {
    if(user && userData && id) {
      let toReturn: BullFolio.Strategy = null;
      
      strategies?.forEach((x) => {
        if(x.id === id) toReturn = x;
      });

      if (toReturn) {
        return toReturn;
      }else{
        try{
          const getByIdF = httpsCallable(functions, "getStrategyById");
          const res = await getByIdF({
            strategyId: id
          });
          const result = res.data as BullFolio.Strategy | null;
          return result;
        }catch(err){
          const error: any = err;
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.log(code, message, details);
          toast(getToast("error", "Something went wrong while getting strategy!", message));
        }
      }
    }
  }

  const getStrategyLogs = async (id: string) => {
    if(user && userData && id) {
      try{
        const getLogsF = httpsCallable(functions, "getStrategyLogs");
        const res = await getLogsF({
          strategyId: id,
          limit: 10
        });
        const result = res.data as {
          logDoc: BullFolio.LogDoc,
          logs: BullFolio.Log[]
        } | null;
        return result;
      }catch(err){
        const error: any = err;
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.log(code, message, details);
        toast(getToast("error", "Something went wrong while getting strategy trades!", message));
      }
    }
  }


  return (
    <StrategiesContext.Provider
      value={{
        createStrategy,
        getMyStrategies,
        deleteStrategy,
        backTestStrategy,
        getAllStrategies,
        getStrategyById,
        getStrategyLogs
      }}
    >
      {children}
    </StrategiesContext.Provider>
  );
};

export const useStrategies = () => {
  return useContext(StrategiesContext);
}
import { Box, Switch, Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Select, Text, useColorModeValue, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Input } from "@chakra-ui/react";
import { BullFolio } from "bullfolio-types";
import InputField from "components/fields/InputField";
import Loading from "components/Loading/Loading";
import { useStrategies } from "contexts/StrategiesContext";
import { useState, useEffect } from "react";
import LineChart from 'components/charts/LineChart';
import { dissolveKey, generateHexColorFromString, isOscillator, secondsToDayMonth } from "helpers/formatters";
import ReactApexChart from "react-apexcharts";

const drawerSizes = {
  sm: '425px',
  md: "425px",
  lg: "1550px"
};

const BackTestDrawer = (props: { strategyData: BullFolio.Strategy, isOpen: boolean, close: () => void, coins: BullFolio.CoinData.Data[] }) => {

  const { strategyData, isOpen, close, coins } = props;
  
  const drawerBg = useColorModeValue('white', 'navy.800');
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const [startingBalance, setStartingBalance] = useState("1");
  const [positionSize, setPositionSize] = useState("0");
  const [span, setSpan] = useState("200");
  const [coinId, setCoinId] = useState("bitcoin");
  const [backTest, setBackTest] = useState<BullFolio.BackTest>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [chartData, setChartData] = useState([
    {
      name: 'Revenue',
      data: [ 50, 64, 48, 66, 49, 68 ]
    },
    {
      name: 'Profit',
      data: [ 30, 40, 24, 46, 20, 46 ]
    }
  ]);
  const [chartOptions, setChartOptions] = useState({
    height: "500px",
    chart: {
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: '#4318FF'
      }
    },
    colors: [ '#4318FF', '#39B8FF' ],
    markers: {
      size: 0,
      colors: 'white',
      strokeColors: '#7551FF',
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      // discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true
    },
    tooltip: {
      theme: 'dark'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      type: 'line'
    },
    xaxis: {
      type: 'numeric',
      categories: [ 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB' ],
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    legend: {
      show: true
    },
    grid: {
      show: false,
      column: {
        color: [ '#7551FF', '#39B8FF' ],
        opacity: 0.5
      }
    },
    color: [ '#7551FF', '#39B8FF' ]
  });

  const [oscillatorData, setOscillatorData] = useState(null);
  const [oscillatorOptions, setOscillatorOptions] = useState(null);

  const { backTestStrategy } = useStrategies();

  const handleBackTest = async () => {
    setIsLoading(true);
    console.log("getting")
    const res = await backTestStrategy(
      strategyData,
      coinId,
      Number(positionSize),
      Number(span),
      Number(startingBalance)
    );
    console.log(res);
    setBackTest(res);
    setIsLoading(false);
  };

  useEffect(() => {
    if(backTest) {
      const _newData = [];
      const _newOscillatorData = [];

      _newData.push({
        name: `${coinId.toUpperCase()} price`,
        data: backTest.prices.map(e => e.price)
      });
      for (const key in backTest.indicators) {
        if(backTest.indicators[key]) {
          const _key = dissolveKey(key);
          const values = backTest.indicators[key];
          const name = `${_key.type.toUpperCase()} ${_key.settings.join(" ")}`;
          if(!isOscillator(_key.type)) {
            _newData.push({
              name: name,
              data: values,
            });
          }else{
            // is oscillator create new chart
            _newOscillatorData.push({
              name: name,
              data: values,
            });
          }
        }
      }

      // colors and xaxsis
      const _newOptions = {...chartOptions};
      _newOptions.colors = _newData.map(x => generateHexColorFromString(x.name));
      _newOptions.colors[0] = "#808080"; // color of price line - same for all tokens
      _newOptions.xaxis.categories = backTest.prices.map(x => secondsToDayMonth(x.timestamp));

      if(_newOscillatorData.length>0) {
        const _newOscillatorOptions = {...chartOptions};
        const _oscillatorData = [
          {
            name: "Overbought",
            data: Array(_newOscillatorData[0].data.length).fill(80)
          },
          {
            name: "Oversold",
            data: Array(_newOscillatorData[0].data.length).fill(20)
          },
          ..._newOscillatorData
        ];

        // create colors
        _newOscillatorOptions.colors = _newData.map(x => generateHexColorFromString(x.name));
        _newOscillatorOptions.colors[0] = "#808080";
        _newOscillatorOptions.colors[1] = "#808080";
        _newOscillatorOptions.xaxis.categories = backTest.prices.map(x => secondsToDayMonth(x.timestamp));
        
        // update
        setOscillatorData(_oscillatorData);
        setOscillatorOptions(_newOscillatorOptions);
      }else{
        setOscillatorData(null);
        setOscillatorOptions(null);
      }

      // update
      setChartData(_newData);
      setChartOptions(_newOptions);
    }
  }, [backTest]);

  return(
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={close}
    >
      <DrawerOverlay />
      <DrawerContent
        w="1000px"
        maxW={drawerSizes}
        mr={{
          sm: '16px'
        }}
        my={{
          sm: '25px'
        }}
        borderRadius='16px'
        bg={drawerBg}
      >
        <DrawerHeader>
          <Text fontSize={"2xl"} mb="1">Back-Testing Results</Text>
          <Text textAlign={"left"} fontSize={"lg"} fontWeight="400">Back-testing results for <b>{strategyData.name}</b> strategy.</Text>
        </DrawerHeader>

        <DrawerBody pt="0" mt="0" className="custom-scroll">
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex='1' textAlign='left'>
                    Back-Testing Settings
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} pt={2}>
                <Box>
                  <FormLabel
                    display='flex'
                    ms='10px'
                    fontSize='sm'
                    color={textColorPrimary}
                    fontWeight='bold'
                    _hover={{ cursor: 'pointer' }}
                  >
                    Token
                  </FormLabel>
                  <Select onChange={(e) => setCoinId(e.target.value)} value={coinId} mb="10px">
                    {coins?.map(coin => {
                      return(
                        <option key={coin.id} value={coin.id}>{coin.name} ({coin.symbol.toUpperCase()})</option>
                      )
                    })}
                  </Select>
                  <InputField
                    label={`Starting Balance (in ${coinId || "BTC"})`}
                    placeholder="0.5"
                    mb="15px"
                    value={startingBalance}
                    onChange={(e: any) => setStartingBalance(e.target.value)}
                  />
                  <Box mb="10px">
                    <FormLabel
                      display='flex'
                      ms='10px'
                      fontSize='sm'
                      color={textColorPrimary}
                      fontWeight='bold'
                      _hover={{ cursor: 'pointer' }}
                    >
                      Position Size
                    </FormLabel>
                    <Flex ms="10px">
                      <Switch
                        id="max"
                        isChecked={positionSize === "0"}
                        onChange={() => positionSize === "0" ? setPositionSize("") : setPositionSize("0")}
                      />
                      <FormLabel htmlFor='isDisabled' ml="3">Use Max (position size will always equal balance)</FormLabel>
                    </Flex>
                    {positionSize !== "0" ? (
                      <>
                        <InputField
                          label={`Position size (in ${coinId || "BTC"})`}
                          placeholder="0.5"
                          value={positionSize}
                          onChange={(e: any) => setPositionSize(e.target.value)}
                        />
                      </>
                    ):null}
                  </Box>
                  <InputField
                    label={`Back-Test History in Days`}
                    placeholder="200"
                    mb="10px"
                    value={span}
                    onChange={(e: any) => setSpan(e.target.value)}
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Button
            variant={"darkBrand"}
            onClick={() => handleBackTest()}
            mt="2"
            mb="6"
          >
            Load Back-Testing Results
          </Button>

          {isLoading ? (
            <Loading text="Back-Testing your strategy, please wait..." />
          ):null}

          {backTest && !isLoading ? (
            <Box>
              <LineChart chartData={chartData} chartOptions={chartOptions} height={500} />
              {oscillatorData && oscillatorOptions ? (
                <LineChart chartData={oscillatorData} chartOptions={oscillatorOptions} height={300} />
              ):null}
            </Box>
          ):null}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="brand" onClick={close}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default BackTestDrawer;
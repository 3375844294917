import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, FormLabel, Icon, Select, Switch, Text, Tooltip } from "@chakra-ui/react"
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { useState, useEffect, useRef, useCallback } from "react";
import { BullFolio } from "bullfolio-types";
import { useCoins } from "contexts/CoinsContext";
import { BiBadge, BiSolidPencil } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { dissolveKey, generateHexColorFromString, getHoursFromTimeframe, getStartTimestampAndLastUpdated, indicatorsKeyToTimeframeKey, isOscillator, padArray, splitPriceData } from "helpers/formatters";
import Loading from "components/Loading/Loading";
import { calculateBollingerBands, calculateEMA, handleCalculateIndicator } from "helpers/indicators";
import LineChart from "components/charts/LineChart";
import { INDICATOR_KEYS } from "helpers/defaultIndicators";
import { IoMdClose } from "react-icons/io";


const Chart = (props: { id: string, coin: BullFolio.CoinDetailedData, bitcoin: BullFolio.CoinDetailedData }) => {

  const { id, coin, bitcoin } = props;
  const { getLevels, getCoinById } = useCoins();

  const [indicators, setIndicators] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [indicatorDropdown, setIndicatorDropdown] = useState<string>("");
  const [timeframe, setTimeframe] = useState<BullFolio.Timeframe>("1d");
  const [prices, setPrices] = useState<BullFolio.CoinData.AllPrices | null>(null);
  const [btcPrices, setBtcPrices] = useState<BullFolio.CoinData.AllPrices | null>(null);
  const [levels, setLevels] = useState<number[]>([]);
  const [priceAgainst, setPriceAgainst] = useState<"btc"|"usd">("usd");
  const [levelsSaved, setLevelsSaved] = useState<"btc"|"usd" | null>(null);
  const [showLevels, setShowLevels] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartOptionsStoch, setChartOptionsStoch] = useState({
    height: "500px",
    chart: {
      dropShadow: {
        enabled: false,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: '#4318FF'
      }
    },
    colors: [ '#4318FF', '#39B8FF' ],
    markers: {
      size: 0,
      colors: 'white',
      strokeColors: '#7551FF',
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      // discrete: [],
      shape: 'circle',
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true
    },
    tooltip: {
      theme: 'dark'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      type: 'line'
    },
    xaxis: {
      type: 'numeric',
      categories: [ 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB' ],
      labels: {
        style: {
          colors: '#A3AED0',
          fontSize: '12px',
          fontWeight: '500'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    legend: {
      show: true
    },
    grid: {
      show: false,
      column: {
        color: [ '#7551FF', '#39B8FF' ],
        opacity: 0.5
      }
    },
    color: [ '#7551FF', '#39B8FF' ]
  });
  const [chartData, setChartData] = useState<any[]>([{
    data: []
  }]);
  const [chartSeries, setChartSeries] = useState<any[]>([{
    data: []
  }]);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      type: "candlestick",
      height: 350
    },
    title: {
      text: 'CandleStick Chart',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  });

  const height = 400;
  const width = window.innerWidth * 0.75;

  const removeIndicator = (indicator: string) => {
    setIndicators(indicators.filter(item => item !== indicator))
  }

  const replaceNulls = (arr: any[], replacementValue: number) => {
    return arr.map(item => item === null ? replacementValue : item);
  }  

  const calculateIndicators = (indicator: string) => {
    const dissolvedKey = dissolveKey(indicator);
    let _prices = [...prices[timeframe], coin.data.current_price];
    if(priceAgainst === "btc") {
        // get btc price
        const pricesBitcoin = [...btcPrices[timeframe], bitcoin.data.current_price];
        // get price in bitcoin
        _prices = divideArrays(_prices, pricesBitcoin);
    }
    const indicatorData = replaceNulls(handleCalculateIndicator(dissolvedKey.type, _prices, dissolvedKey.settings), _prices[0]);
    const lenDif = prices[timeframe].length - indicatorData.length;
    const result:{x: Date, y: number}[] = [];

    _prices.forEach((price, i) => {
      const timestamp = getTimestamp(
        getStartTimestampAndLastUpdated(timeframe, prices[timeframe].length).timestampInSeconds,
        getHoursFromTimeframe(timeframe),
        i+1,
      );
      if(i >= lenDif+1) {
        const indicatorsIndex = i - lenDif;
        result.push({
          x: new Date(timestamp),
          y: indicatorData[indicatorsIndex]
        })
      }else{
        result.push({
          x: new Date(timestamp),
          y: indicatorData[0]
        })
      }
    });

    return [{
      data: result,
      type: "line",
      color: generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`),
      name: `${dissolvedKey.type.toUpperCase()} - ${dissolvedKey.settings}`,
    }];
  }

  const createOscillatorChart = (indicator: string) => {
    const dissolvedKey = dissolveKey(indicator);
    const chartData = calculateIndicators(indicator);

    if (indicator.includes("rsi_ma")) {
      // calculate rsi
      chartData.push(
        calculateIndicators(`rsi:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
      );
    }

    if(indicator.includes("rsi:")) {
      //calculate rsi ma
      chartData.push(
        calculateIndicators(`rsi_ma:${[...dissolvedKey.settings, 14].join(",")}:${dissolvedKey.timeframe}`)[0]
      );
    }

    if (indicator.includes("bbwp")) {
      if (indicator.includes("ma")) {
        // calculate bbwp
        chartData.push(
          calculateIndicators(`bbwp:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      } else {
        //calculate bbwp ma
        chartData.push(
          calculateIndicators(`bbwp_ma:${[...dissolvedKey.settings, 5].join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }
    }

    if (indicator.includes("stoch")) {
      if (indicator.includes("_k")) {
        // calculate d
        chartData.push(
          calculateIndicators(`stoch_rsi_d:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }else{
        // calculate k
        chartData.push(
          calculateIndicators(`stoch_rsi_k:${dissolvedKey.settings.join(",")}:${dissolvedKey.timeframe}`)[0]
        );
      }
    }

    const chartOptions: ApexOptions = {
      chart: {
        type: "line",
        height: 150,
      },
      title: {
        text: indicator,
        align: 'left'
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        tooltip: {
          enabled: true
        }
      },
      colors: [generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`)],
      stroke: {
        width: 1.25
      },
      grid: {
        
      }
    };
    console.log("oscillator chart data");
    console.log(chartData);
    return {
      chartData,
      chartOptions,
    }
  }
 
  const divideArrays = (x: number[], y: number[]) => {
    // Ensure both arrays have the same length
    const minLength = Math.min(x.length, y.length);
    x = x.slice(x.length - minLength);
    y = y.slice(y.length - minLength);
  
    // Perform the division
    const result = [];
  
    for (let i = 0; i < minLength; i++) {
      if (y[i] !== 0) {  // Check to avoid division by zero
        const divisionResult = x[i] / y[i];
        result.push(divisionResult);
      }
    }
  
    return result;
  };  

  const getTimestamp = (timestampInSeconds: number, x: number, y: number): number => {
    // Convert timestamp from seconds to milliseconds
    let timestampInMilliseconds = timestampInSeconds * 1000;
    
    // Calculate total hours to add
    const totalHours = x * y;
    
    // Convert hours to milliseconds
    const millisecondsToAdd = totalHours * 60 * 60 * 1000;
    
    // Add the milliseconds to the original timestamp
    timestampInMilliseconds += millisecondsToAdd;
    
    return timestampInMilliseconds;
  };  

  useEffect(() => {
    console.log("in use effect ", timeframe, prices, btcPrices, priceAgainst);
    if(timeframe && prices && priceAgainst) {
      let _prices = prices[timeframe];
      const result:{x: Date, y: number[]}[] = [];

      if(priceAgainst === "btc") {
        // get btc price
        const prevPrices = [..._prices];
        const pricesBitcoin = btcPrices[timeframe];

        // get price in bitcoin
        _prices = divideArrays(prevPrices, pricesBitcoin);
      }

      _prices.forEach((x, index) => {
        if(index !== 0) {
          const openPrice = _prices[index-1];
          const closePrice = x;
          const timestamp = getTimestamp(
            getStartTimestampAndLastUpdated(timeframe, _prices.length).timestampInSeconds,
            getHoursFromTimeframe(timeframe),
            index,
          );
          
          result.push({
            y: [openPrice, openPrice, closePrice, closePrice],
            x: new Date(timestamp)
          });
        }
      });

      // push current candle (that is still open)
      const currentPrice = priceAgainst === "btc" ? coin.data.current_price / bitcoin.data.current_price : coin.data.current_price;
      result.push({
        x: new Date(),
        y: [_prices[_prices.length-1], _prices[_prices.length-1], currentPrice, currentPrice]
      });

      const _chartSeries: {data: {x: Date, y: number[] | number}[], type: string, name: string, color?: string }[] = [{
        data: result,
        type: "candlestick",
        name: "Chart",
        color: "#000000",
      }];

      // add any indicators
      indicators.forEach((indicator, i) => {
        const dissolvedKey = dissolveKey(indicator);
        if(!isOscillator(dissolvedKey.type)) {
          console.log("adding");
          // add to chart
          const _indicatorChartData = createOscillatorChart(indicator).chartData;
          _chartSeries.push({
            ..._indicatorChartData[0],
            color: generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`),
            name: `${dissolvedKey.type.toUpperCase()} - ${dissolvedKey.settings}`,
          });
          setColors([...colors, generateHexColorFromString(`${dissolvedKey.type}${dissolvedKey.settings}`)]);
        }
      });

      console.log(_chartSeries);

      setChartSeries(_chartSeries);

      (async () => {
        if(showLevels && levelsSaved !== priceAgainst) {
          console.log("in if levels")
          setLevels([]);
          let newLevels = [];
          if(priceAgainst === "btc") {
            // get
            setIsLoading(true);
            const _levels = await getLevels([].concat(..._prices));
            newLevels = _levels;
            setIsLoading(false);
            setLevelsSaved("btc");
          }else{
            // use coins data
            newLevels = coin.levels[0].levels;
            setLevelsSaved("usd");
          }
          setLevels(newLevels);
        }
      })();
    }
  }, [timeframe, coin, priceAgainst, bitcoin, showLevels, prices, btcPrices, indicators]);

  useEffect(() => {
    if(chartSeries) {
      console.log(chartSeries)
      setChartOptions({
        chart: {
          type: "candlestick",
          height: height
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        colors: colors,
        stroke: {
          width: 1.5
        },
        annotations: levels.length>0 ? {
          yaxis: levels.map((x) => ({
            y: x,
            borderColor: '#000000',
            label: {
              borderColor: '#000000',
              style: {
                color: '#fff',
                background: '#000000'
              },
              borderWidth: 3,
              text: ""
            }
          }))
        } : {},
      });
    }
  }, [chartSeries, levels]);

  useEffect(() => {
    (async function () {
      console.log("getting price chart for id ", id);
      const _res = await getCoinById(id);
      setPrices(_res.prices);
      if(id !== "bitcoin") {
        console.log("getting price chart for BITCOIN");
        const _resBtc = await getCoinById("bitcoin");
        setBtcPrices(_resBtc.prices);
      }
    })();
  }, [id]);

  
  // DRAWING
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [isDrawingOn, setIsDrawingOn] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if(canvas) {
      const ctx = canvas.getContext('2d');
      ctx.lineWidth = 3;
      ctx.lineJoin = 'round';
      ctx.lineCap = 'round';
      ctx.strokeStyle = 'blue';
      setContext(ctx);
      console.log("setting ctx")
    }
  }, [canvasRef, isDrawingOn]);

  const startDrawing = (event: any) => {
    setIsDrawing(true);
    context.beginPath();
    context.moveTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    context.closePath();
  };

  const draw = (event: any) => {
    if (!isDrawing) return;
    context.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    context.stroke();
  };

  const clearCanvas = () => {
    if (context) {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  const overlayStyles = {
    position: "absolute" as "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: "10px",
    zIndex: -1,
    width: `${window.innerWidth * 0.65}px`,
    height: `${window.innerHeight * 0.75}px`,
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"} px="2.5" mb="2" mt="8">
        <Flex>
          <Text fontSize={"xl"} fontWeight={"800"}>
            {coin.data.symbol.toUpperCase()} /
          </Text>
          <Select width={"fit-content"} defaultValue={priceAgainst} onChange={(e) => setPriceAgainst(e.target.value as "usd"|"btc")} ml="2" mt="-1.5">
            <option value={"usd"}>USD</option>
            <option value={"btc"}>BTC</option>
          </Select>
        </Flex>
        <Flex>
          <Flex>
            <Text fontSize={"lg"} fontWeight={"width"} mt="1.5">Candle Timeframe</Text>
            <Select width={"fit-content"} defaultValue={timeframe} onChange={(e) => setTimeframe(e.target.value as BullFolio.Timeframe)} ml="2">
              <option value={"1h"}>1 H</option>
              <option value={"4h"}>4 H</option>
              <option value={"8h"}>8 H</option>
              <option value={"1d"}>1 D</option>
              <option value={"2d"}>2 D</option>
              <option value={"5d"}>5 D</option>
              <option value={"1w"}>1 W</option>
            </Select>
          </Flex>
          <Tooltip label="Toggle drawing mode.">
            <Button variant={"brand"} ml="5" onClick={() => setIsDrawingOn(!isDrawingOn)}>
              <Icon as={isDrawingOn ? RxCross2 : BiSolidPencil} h="25px" />
            </Button>
          </Tooltip>
          {isDrawingOn ? (
            <Tooltip label="Clear All">
              <Button ml="2" onClick={clearCanvas}>
                <Icon as={FiTrash2} h="25px" />
              </Button>
            </Tooltip>
          ):null}
        </Flex>
      </Flex>

      <Flex mb="1.5">
        <Select placeholder='Select Indicator' width={"fit-content"} onChange={(e) => setIndicatorDropdown(e.target.value)} ml="2">
          {INDICATOR_KEYS.map((key, index) => {
            if(!indicators.includes(key)) {
              const dissolvedKey = dissolveKey(key);
              if(indicatorsKeyToTimeframeKey(dissolvedKey.timeframe) === timeframe) {
                return(
                  <option value={key} key={index}>{key}</option>
                )
              }
            }
          })}
        </Select>
        <Button ml="2" onClick={() => setIndicators([...indicators, indicatorDropdown])}>
          Add to chart
        </Button>
      </Flex>

      <Flex mb="4" px="6">
        {indicators.map((indicator, index) => {
          const dissolvedKey = dissolveKey(indicator);
          if(!isOscillator(dissolvedKey.type)) {
            return(
              <Flex key={index}>
                <p>{indicator}</p>
                <Button ml="2" onClick={() => removeIndicator(indicator)} size="xs">
                  <IoMdClose />
                </Button>
              </Flex>
            )
          }
        })}
      </Flex>

      {isDrawingOn ? (
        <div className="drawing-container">
          <div style={overlayStyles}>
            <div id="chart">
              <ReactApexChart options={chartOptions} series={chartSeries} type="candlestick" height={height} width={width} />
            </div>
          </div>
          <canvas
            ref={canvasRef}
            className="drawing-canvas"
            height={height}
            width={width}
            onMouseDown={startDrawing}
            onMouseUp={stopDrawing}
            onMouseOut={stopDrawing}
            onMouseMove={draw}
            onTouchStart={startDrawing}
            onTouchEnd={stopDrawing}
            onTouchCancel={stopDrawing}
            onTouchMove={draw}
          />
        </div>
      ):(
        <div id="chart">
          <ReactApexChart options={chartOptions} series={chartSeries} type="candlestick" height={height} width={width} />
        </div>
      )}

      <Accordion allowToggle mb="2">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                Oscillators
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} pt={2}>
            {indicators.map((indicator, index) => {
              const dissolvedKey = dissolveKey(indicator);
              if(isOscillator(dissolvedKey.type)) {
                const chart = createOscillatorChart(indicator);
                return(
                  <Flex key={index}>
                    <ReactApexChart options={chart.chartOptions} series={chart.chartData as unknown as any[]} type="line" height={height/2} width={width} />
                    <Button ml="2" onClick={() => removeIndicator(indicator)}>
                      <IoMdClose />
                    </Button>
                  </Flex>
                )
              }
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      
      {!isLoading ? (
        <Flex px="2">
          <Switch
            isChecked={showLevels}
            onChange={() => setShowLevels(!showLevels)}
            mr="3"
          />
          <FormLabel>Show AI generated support/resistance levels</FormLabel>
        </Flex>
      ):(
        <Loading text="Generating support/resistance levels..." />
      )}

      {chartData[0].data.length>10 && chartOptionsStoch.xaxis.categories.length>10 ? (
        <ReactApexChart options={chartOptions} series={chartData} type="candlestick" height={250} width={width} />
      ):(
        <Text>Loading</Text>
      )}
    </Box>
  );
};

export default Chart;
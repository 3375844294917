import { Box, Flex, Switch, SimpleGrid, Text, useColorModeValue, Tooltip } from "@chakra-ui/react"
import { BullFolio } from "bullfolio-types";
import PieChart from "components/charts/PieChart";
import { chainIdToTicker, chainIdToTokenId, generateHexColorFromString } from "helpers/formatters";
import { useEffect, useState } from "react";
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import { useCoins } from "contexts/CoinsContext";
import numeral from "numeral";
import { useUser } from "contexts/UserContext";


const PieChartWatchlist = (props: { watchlist: BullFolio.Watchlist }) => {
  const { watchlist } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const cardColor = useColorModeValue('white', 'navy.700');
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [percentage, setPercentage] = useState(false);

  const { userData } = useUser();

  const findNumberInArray = (array: number[], number: number) => {
    const TRESHOLD = 0.015;
    const lowerBound = number - (number * TRESHOLD); // Calculate the lower bound only once
    const upperBound = number + (number * TRESHOLD); // Calculate the upper bound only once

    for (let i = 0; i < array.length; i++) {
      const currentNumber = array[i];
      if (currentNumber >= lowerBound && currentNumber <= upperBound) {
        return i; // Return the index of the first number in the range
      }
    }
    return -1; // Return -1 if no number in the range is found
  }

  const displayTokenValue = (val: number) => {
    if(percentage) {
      const TOTAL = chartData?.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0).toFixed(0);
      const per = val / TOTAL;
      return numeral(per).format('0.0%');
    }else{
      return numeral(val.toFixed(0)).format("$0,0.00")
    }
  }

  const haveCommonConsecutiveLetters = (str1: string, str2: string) => {
    for (let i = 0; i < str1.length - 1; i++) {
      const consecutiveLetters = str1[i] + str1[i + 1];
  
      if (str2.includes(consecutiveLetters)) {
        return true;
      }
    }
  
    return false;
  }  

  useEffect(() => {
    if(watchlist) {
      (async () => {
        const tokenNames: string[] = [];
        const values: number[] = [];
        const colors: string[] = [];

        watchlist.tokens.forEach((token) => {
          const _tokenData = userData.allIds.coins.find((x) => x.id === token.id);
          if(_tokenData) {
            console.log(_tokenData);
            const balance = Number(token.currentBalance)
            const hodlValue = Number((balance * _tokenData.current_price).toFixed(2));
            tokenNames.push(`${_tokenData.name} (${_tokenData?.symbol?.toUpperCase()})`);
            values.push(Number(hodlValue.toFixed(2)));
            // generate color from address (so that it is always the same)
            colors.push(generateHexColorFromString(token.id));
          }
        });

        // Create an array of objects containing numbers, tickers, and colors
        const combinedArray = values.map((number, index) => ({
          number,
          ticker: tokenNames[index],
          color: colors[index],
        }));

        // Sort the combined array based on numbers
        combinedArray.sort((a, b) => a.number - b.number).reverse();

        // Extract the sorted tickers and colors back into separate arrays
        const sortedTickers = combinedArray.map(item => item.ticker);
        const sortedColors = combinedArray.map(item => item.color);
        const sortedNumbers = combinedArray.map(item => item.number);

        const pieChartOptions = {
          labels: sortedTickers,
          colors: sortedColors,
          chart: {
            width: '1000px',
            innerHeight: "1000px",
          },
          states: {
            hover: {
              filter: {
                type: 'none'
              }
            }
          },
          legend: {
            show: false
          },
          dataLabels: {
            enabled: false
          },
          // hover: { mode: null },
          plotOptions: {
            donut: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: false
                }
              }
            }
          },
          fill: {
            colors: sortedColors,
          },
          tooltip: {
            enabled: true,
            theme: 'light'
          }
        };
        
        setChartData(sortedNumbers);
        setChartOptions(pieChartOptions);
      })();
    }
  }, [watchlist]);

  return(
    <Box px='10px' alignItems='center' flexDirection='column' w='100%'>
			<Flex
				px={{ base: '0px', '2xl': '10px' }}
				justifyContent='space-between'
				alignItems='center'
				w='100%'
        height={"100%"}
				mb='8px'>
				<Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
					Balance: {numeral(chartData?.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0).toFixed(0)).format("$0,0.00")}
				</Text>
			</Flex>

			{chartData && chartOptions ? (
        <PieChart
          chartData={chartData}
          chartOptions={chartOptions}
        />
      ):null}

      <Flex mt="2">
        <Switch
          isChecked={percentage}
          onChange={() => setPercentage(!percentage)}
          mr="2"
          mt="0.5"
          />
        <Text>Show Percentage Values</Text>
      </Flex>

			<Card
				bg={cardColor}
				boxShadow={cardShadow}
				w='100%'
				p='15px'
				px='20px'
				mt='15px'
				mx='auto'
      >
        <SimpleGrid columns={5} justifyContent="space-between">
          {chartOptions?.labels.map((label: string, index: number) => {
            return(
              <Box key={`${label}-${index}`}>
                <Flex direction='column' py='5px'>
                  <Flex align='center'>
                    <Box h='8px' w='8px' bg={chartOptions.colors[index]} borderRadius='50%' me='4px' />
                    <Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
                      {label}
                    </Text>
                  </Flex>
                  <Text fontSize='lg' color={textColor} fontWeight='700'>
                    {displayTokenValue(chartData[index])}
                  </Text>
                </Flex>
                <VSeparator mx={{ base: '60px', xl: '60px', '2xl': '60px' }} />
              </Box>
            )
          })}
        </SimpleGrid>
			</Card>
		</Box>
  );
};

export default PieChartWatchlist;